import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const BellIcon = ({ className, onClick }: Props) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
      <path d="M9 4C9 3.20435 8.68393 2.44129 8.12132 1.87868C7.55871 1.31607 6.79565 1 6 1C5.20435 1 4.44129 1.31607 3.87868 1.87868C3.31607 2.44129 3 3.20435 3 4C3 7.5 1.5 8.5 1.5 8.5H10.5C10.5 8.5 9 7.5 9 4Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.86477 10.5C6.77686 10.6515 6.65069 10.7773 6.49888 10.8648C6.34707 10.9522 6.17495 10.9982 5.99977 10.9982C5.82458 10.9982 5.65246 10.9522 5.50065 10.8648C5.34884 10.7773 5.22267 10.6515 5.13477 10.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default BellIcon;
