import { camelizeKeys } from '@zoocasa/node-kit/objects/transform-keys';
import endpoint from 'utils/endpoint';
import { Point } from 'data/listing';
import { ProvinceOrStateCode, provinceOrStateNameFromCode } from 'utils/province_or_state';
import geojsonPolygonUnion from 'utils/geojson/geojson-polygon-union';
import { isPointInPolygon } from 'geolib';
import decryptPayload from 'utils/decrypt-payload';

import type PolygonType from 'data/geojson/polygon/type';
import type { GeolibInputCoordinates } from 'geolib/es/types';

type RatingAverage = {
  reference: number | null;
  value: number | null;
};

export type RatingValue = {
  year: number | null;
  value: number | null;
  reference: number | null;
};

export type RatingDatum = {
  title: string | null;
  values: RatingValue[] | null;
};

type Polygon = {
  type: 'Polygon';
  coordinates: [[]][] | null;
};

export default class School {
  id: number;
  name: string;
  address: string | null;
  boardName: string | null;
  city: string | null;
  demographicsData: {
    giftedStudents: RatingAverage | null;
    lowerIncome: RatingAverage | null;
    parentsEducation: RatingAverage | null;
    specialEd: RatingAverage | null;
  } | null;
  eqaoRating: number | null;
  enrollment: number | null;
  isCatholic: boolean;
  level: 'elementary' | 'intermediate' | 'secondary';
  slug: string;
  minGrade: number;
  maxGrade: number;
  language: string | null;
  schoolType: 'public' | 'private' | 'independent' | 'catholic';
  phoneNumber: number | null;
  position: Point;
  provinceCode: string;
  ratingsData: {
    eqaoAverage: RatingAverage;
    eqaoGrade3Data: RatingDatum[] | null;
    eqaoGrade6Data: RatingDatum[] | null;
    eqaoGrade9Data: RatingDatum[] | null;
    fraserAverage: RatingAverage;
    fraserRatingData: RatingDatum[] | null;
  } | null;
  website: string | null;
  boundaries: {
    elementary: Polygon | Record<string, never>;
    secondary: Polygon | Record<string, never>;
  };

  constructor(school: Record<string, unknown>) {
    const camelizedSchool = camelizeKeys(school);
    const attributes = camelizedSchool.attributes as Record<string, unknown>;
    const relationships = camelizedSchool.relationships as Record<
      string,
      unknown
    >;
    const formattedSchool = {
      ...attributes,
      ...relationships,
      id: camelizedSchool.id,
      position: attributes.position ? new Point(attributes.position as Record<string, unknown>) : null,
    } as School;
    Object.assign(this, formattedSchool);
  }

  get boundary() {
    let boundary: Polygon | Record<string, never>;
    const { boundaries, level } = this;
    if (boundaries) {
      const { elementary, secondary } = boundaries;
      if (level === 'elementary') {
        boundary = elementary;
      } else if (level === 'secondary') {
        boundary = secondary;
      } else {
        return null;
      }
      return ('type' in boundary && boundary.coordinates) ? boundary : null;
    }
    return null;
  }

  get nearbyBoundary() {
    const [lng, lat] = this.position.coordinates;
    const left = lng - 0.02;
    const right = lng + 0.02;
    const bottom = lat - 0.015;
    const top = lat + 0.015;
    return {
      type: 'Polygon',
      coordinates: [[
        [left, bottom],
        [right, bottom],
        [right, top],
        [left, top],
        [left, bottom],
      ]],
    };
  }

  get grades() {
    const { minGrade, maxGrade } = this;
    return `${minGrade > 0 ? minGrade : minGrade === 0 ? 'SK' : 'JK' }-${maxGrade}`;
  }

  get listingsQueryParams() {
    const boundary = this.boundary;
    const boundaryFilter = boundary ? geojsonPolygonUnion(boundary as unknown as PolygonType) : this.nearbyBoundary;
    return {
      filter: {
        boundary: JSON.stringify(boundaryFilter),
      },
    };
  }

  get location() {
    const { city, provinceCode } = this;
    const province = provinceOrStateNameFromCode(provinceCode.toLowerCase() as ProvinceOrStateCode );
    return `${city}, ${province}`;
  }

  boundaryIncludesPoint = (point: GeolibInputCoordinates) => {
    if (this.boundary?.coordinates) {
      const coordinates = this.boundary.coordinates as unknown as [number, number][][];
      for (let i = 0; i < coordinates.length; i++) {
        if (isPointInPolygon(point, coordinates[i])) {
          return true;
        }
      }
    }
    return false;
  };
}

export async function getSchools(params: Record<string, any>) {
  try {
    const payload = await endpoint<{ data: string }>('/services/api/v3/schools', 'GET', params);
    const data = decryptPayload(payload).data;
    if (Array.isArray(data)) {
      return data.map(d => new School(d));
    }
    return new School(data);
  } catch {
    return null;
  }
}

export async function getNearbySchools(params: Record<string, any>) {
  try {
    const payload = await endpoint<{ data: string }>('/services/api/v3/schools/nearby-schools', 'GET', params);
    const decryptedPayload = decryptPayload(payload);
    const schools = decryptedPayload.data.map((school: Record<string, any>) => {
      return new School(school);
    });
    return schools;
  } catch {
    return [];
  }
}
