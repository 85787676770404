import React from 'react';
import SchoolPinIcon from 'components/icon/school-pin-icon';
import styles from './style.module.scss';
import { buildClassName } from 'utils/build-class-name';

interface Props {
  isExpTheme?: boolean;
}
export default function SchoolPin({ isExpTheme = false }: Props) {
  return (
    <span className={buildClassName(styles.component, isExpTheme && styles['exp-theme'])}>
      <SchoolPinIcon />
    </span>
  );
}
