// ----- Public Functions -----

import { Sort } from 'contexts/preferences/listing-params/types';

// Note: Search params are purely V3 params
export default function mapSearchParams(sort: Sort, filter:any, boundary: any, pageNumber = 1) {
  filter = modifyFilter(filter, boundary);
  return { sort: sort, include: 'clusters,locations', filter: { ...filter, slug: '', areaName: '' }, page: { number: pageNumber, size: 24 }};
}

// ----- Private Functions -----
function modifyFilter(filter: any, boundary: any) {
  filter = JSON.parse(JSON.stringify(filter));
  filter.boundary = boundary ? JSON.stringify(boundary) : null;
  const { houseDetached, houseSemidetached, houseAttached, condo, townhouse } = filter.homeType;
  if (!houseDetached && !houseSemidetached && !houseAttached) {
    delete filter.additional.house;
  }
  if (!condo && !townhouse) {
    delete filter.additional.condoOrTownhouse;
  }

  return filter;
}
