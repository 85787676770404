import React from 'react';

interface Props {
  className?: string;
}

const CompassArrowIcon = ({ className }: Props) => {
  return (
    <svg width="32px" height="32px" className={className} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="🌀-Iconography" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Elements-/-Iconography" transform="translate(-1074.000000, -1133.000000)" fill="#000000" fillRule="nonzero">
          <g id="compass" transform="translate(1074.000000, 1133.000000)">
            <path d="M30.1583822,0.10291979 L0.828756553,12.1014037 C-0.404775886,12.6060274 -0.216237087,14.4085496 1.09503558,14.646957 L14.8518063,17.1482034 L17.3530544,30.9049651 C17.5914619,32.2162369 19.3939853,32.4047756 19.8986094,31.171244 L31.8971011,1.84163759 C32.3446699,0.747525838 31.2524947,-0.344711147 30.1583822,0.10291979 Z M19.1595773,25.9357181 L17.3099973,15.7631285 C17.2106973,15.2168225 16.783126,14.7892515 16.2368197,14.6899516 L6.06422343,12.8403728 L28.2255627,3.77439336 L19.1595773,25.9357181 Z" id="Shape"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CompassArrowIcon;
