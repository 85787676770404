import React, { useState } from 'react';
import { buildClassName } from 'utils/build-class-name';
import { formatNumberWithSuffix } from 'utils/format-number-with-suffix';
import styles from './style.module.scss';

interface Props {
  listings: any[];
  price?: number;
  isPrivate?: boolean;
  hasBeenViewed: boolean;
}

export default function Pin({ listings, price, isPrivate, hasBeenViewed }: Props) {
  const [hasBeenVisited, setHasBeenVisited] = useState(hasBeenViewed);
  const shouldShowPrice = price && !isPrivate;

  const getPinClass = () => {
    if (shouldShowPrice) {
      return styles['price-pin'];
    } else {
      const pinSize = `size-${listings.length < 3 ? listings.length : 3}`;
      return styles[pinSize];
    }
  };

  const getNodeLabel = () => {
    if (shouldShowPrice) {
      return formatNumberWithSuffix(Number(price));
    } else if (listings.length > 1) {
      return listings.length;
    }
  };

  const shouldShowHighlightedState = () => price && hasBeenVisited ? true : false;


  let hoverTimeout: NodeJS.Timeout;

  const mouseHoverHandler = () => {
    hoverTimeout = setTimeout(() => {
      setHasBeenVisited(true);
    }, 600);
  };

  const mouseExitHoverHandler = () => {
    clearTimeout(hoverTimeout);
  };

  return (
    <div className={buildClassName(styles.component, getPinClass(), shouldShowHighlightedState() && styles['visited'])} onMouseEnter={mouseHoverHandler} onMouseLeave={mouseExitHoverHandler}>
      {getNodeLabel()}
      {shouldShowPrice && <span className={styles.nib}></span>}
    </div>
  );
}
