import { MapPageCluster, SortBy } from '@zoocasa/go-search';
import { ZoomLevelGeohashCache } from './geohash_cache_by_zoom_level';
import { Bounds } from './types';
import { Precision } from './geo_helper';
import { getGoSearchHost, isServerSide } from 'utils/host-config';
import { SearchApi, SearchApiType } from 'data/search/api';
import { GeoSearchFilter } from 'data/search/map/types';
import { MAP_PAGE_CONSTANTS } from 'constants/pagination';

export class SearchPageModel {
  cache: ZoomLevelGeohashCache<MapPageCluster> = new ZoomLevelGeohashCache<MapPageCluster>();
  #searchApi: SearchApiType;

  /**
   * Creates a new SearchPageModel instance and configures the search api with the given useLegacySearchFilter parameter. Legacy search filter does not include farm, land, or commercial properties.
   * 
   * @param useLegacySearchFilter Whether to use the legacy search filter. Defaults to false
   */
  constructor(useLegacySearchFilter: boolean = false) {
    this.#searchApi = SearchApi.create(getGoSearchHost(isServerSide()), useLegacySearchFilter);
  }

  /**
   * Fetches clusters within a boundary using the given precision, filter. Listings within a cluster are sorted by the given sortBy parameter and defaults to date descending if not provided.
   * 
   * @param bounds The bounding box to search within
   * @param precision The precision of the search. See [Geohash](https://en.wikipedia.org/wiki/Geohash#Digits_and_precision_in_km) for more details.
   * @param sortBy The sort order of the results. Defaults to date descending if not provided
   * @param filter The filter to apply to the search
   * @param signal An optional AbortSignal to cancel the request
   * @param size An optional number of results to return. Defaults to MAP_PAGE_CONSTANTS.DEFAULT_CLUSTER_DATA_SIZE if not provided
   */
  async getClustersWithinBoundary(bounds: Bounds, precision: Precision, sortBy: SortBy = SortBy.DateDesc, filter: GeoSearchFilter, signal?: AbortSignal, size = MAP_PAGE_CONSTANTS.DEFAULT_CLUSTER_DATA_SIZE) {
    const { clusters } = await this.#searchApi.searchClustersInBoundary(bounds, precision, filter, sortBy, signal, size);
    return clusters;
  } 

  /**
   * Fetches listings within a boundary using the given precision, page, filter parameters. Listings are sorted by the given sortBy parameter and defaults to date descending if not provided.
   * 
   * @param bounds The bounding box to search within
   * @param precision The precision of the search. See [Geohash](https://en.wikipedia.org/wiki/Geohash#Digits_and_precision_in_km) for more details.
   * @param page The page number to return
   * @param sortBy The sort order of the results. Defaults to date descending if not provided
   * @param filter The filter to apply to the search
   * @param signal An optional AbortSignal to cancel the request
   * @param size An optional number of results to return. Defaults to MAP_PAGE_CONSTANTS.DEFAULT_CLUSTER_DATA_SIZE if not provided
   */
  async getListingsWithinBoundary(bounds: Bounds, precision: Precision, page: string, sortBy: SortBy = SortBy.DateDesc, filter: GeoSearchFilter, signal?: AbortSignal, size = MAP_PAGE_CONSTANTS.DEFAULT_CLUSTER_DATA_SIZE) {
    const listings = await this.#searchApi.searchListingsInBoundary(bounds, precision, page, filter, sortBy, signal, size);

    return listings;
  } 
}