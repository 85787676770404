import React from 'react';
import SqftText from 'components/sqft-text';
import styles from './style.module.scss';
import { listingCardIds, blurIds } from 'constants/test-constants';

import type Listing from 'data/listing';
import type { IModalContext } from 'contexts/modal';

interface Props {
  listing: Listing;
  isPrivate: boolean;
  requiresTerms: boolean;
  openModal: IModalContext['openModal'];
}

export default function ListingBedBathParking({ listing, isPrivate, requiresTerms, openModal }: Props) {
  const openTermsModal = () => {
    if (requiresTerms) {
      openModal('listing', { externalUrl: listing.addressPathWithFallback, listingId: listing.id });
      openModal('terms-of-use', { providerId: listing.providerId });
    }
  };

  const renderLabel = () => {
    if (isPrivate) {
      return (
        <div className={styles['sign-in']} data-testid={blurIds.overlaySignInMsg}>
          Real estate boards require that you sign in to see listing details
        </div>
      );
    } else if (requiresTerms) {
      return (
        <div className={styles['sign-in']}>
          Accept &nbsp;
          <a className="primary" onClick={() => openTermsModal()}>Terms of Use</a> to view this listing
        </div>
      );
    } else {
      return (
        <div className={styles.row}>
          <div className={styles['listing-detail']} data-testid={listingCardIds.listingBeds}>
            {listing.bedrooms} Bed
          </div>
          <div className={styles.slash}>|</div>
          <div className={styles['listing-detail']} data-testid={listingCardIds.listingBathrooms}>
            {listing.bathrooms} Bath
          </div>
          <div className={styles.slash}>|</div>
          <div className={styles['listing-detail']} data-testid={listingCardIds.listingSqft}>
            <SqftText squareFootageLabel={listing.sqftLabel} render={formattedSqft => <>{formattedSqft}</>} />
          </div>
        </div>
      );
    }
  };

  return renderLabel();
}
