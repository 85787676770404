import React, { useContext, useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { createRoot } from 'react-dom/client';
import { useIsNotMobile } from 'hooks/use-size-class';
import Popover from 'components/search/map/popover';
import MapControls from 'components/search/map/map-controls';
import { MapContext } from 'contexts/map';
import { PopupContext } from 'contexts/popup';
import { getFilterParamsFromURLQuery } from 'utils/listing-query-helper';
import { PreferencesContext, IPreferences } from 'contexts/preferences';
import SchoolPin from './pin/school';
import Pin from './pin';
import LoadingSpinner from 'components/loading-spinner';
import styles from './style.module.scss';
import { addPinToVisited, hasPinBeenViewed } from 'utils/visited-map-pins';
import { searchFilterIds } from 'constants/test-constants';
import { IOverlayContext, OverlayContext } from 'contexts/overlay';
import { IUserContext, UserContext } from 'contexts/user';
import { v4 as uuidv4 } from 'uuid';
import storage from 'utils/storage';
import { SEARCH_PAGE_MOBILE_SLIDE_UP_LISTING_DATA_COOKIE } from 'constants/cookies';

import type { IPopupContext } from 'contexts/popup';
import type { IMapContext } from 'contexts/map';
import type { Cluster } from 'components/search/types';
import type { MapkitMappable } from '@zoocasa/node-kit/map/apple/types';
import type School from 'data/schools';

interface Props {
  clusters: Cluster[];
  schools: School[];
  showsLoading: boolean;
  showsSidePanel: boolean;
  isSidePanelFullScreen: boolean;
}

const Map = ({ clusters, schools, showsLoading, showsSidePanel, isSidePanelFullScreen }: Props) => {
  const mapElementId = 'search-map';
  const {
    map,
    hasDrawnMap,
    setHasDrawnMap,
    setCurrentPins,
  } = useContext(MapContext) as IMapContext;

  const {
    popupPosition,
    setPopupPosition,
    setClusterPopupData,
    setIsHoveringPin,
    setSchoolPopupData,
    schoolPopupData,
  } = useContext(PopupContext) as IPopupContext;

  const {
    setMapBoundary,
    filterBoundaries,
    setFilterBoundaries,
    overlays,
    setOverlays,
  } = useContext(OverlayContext) as IOverlayContext;

  const { listingParams, schoolParams, updateSchoolParams } = useContext(PreferencesContext) as IPreferences;
  const { isAuthenticated } = useContext(UserContext) as IUserContext;
  const [schoolBoundaryOverlay, setSchoolBoundaryOverlay] = useState<ReturnType<typeof map.makePolygon> | null>();
  const isNotMobile = useIsNotMobile();
  const [isMobileSlideUpVisible, setIsMobileSlideUpVisible] = useState(false);
  const [MobileSlideUp, setMobileSlideUp] = useState<any>();
  const [existingMobileSlideUpListings, setExistingMobileSlideUpListings] = useState<number[]>([]);

  // Update listings when school boundary changes
  useEffect(() => {
    if (hasDrawnMap) {
      const schoolOverlays = map.drawBoundaryPolygons(overlays, filterBoundaries);
      setOverlays(schoolOverlays);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDrawnMap, filterBoundaries]);

  // School Hover boundary control
  useEffect(() => {
    if (hasDrawnMap) {
      if (schoolPopupData) {
        if (schoolPopupData.school.boundary && !schoolBoundaryOverlay) {
          const overlay = map.makePolygon(map.boundaryFromGeoJsonPolygon(schoolPopupData.school.boundary) as any);
          setSchoolBoundaryOverlay(overlay);
        }
      } else if (!schoolPopupData && schoolBoundaryOverlay) {
        map.removeOverlay(schoolBoundaryOverlay);
        setSchoolBoundaryOverlay(null);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolPopupData]);

  // Draw map and add handlers
  useEffect(() => {
    if (map && (window as any).mapkit) {
      const hasUrlParams = !!window.location.search.length;
      const paramsFromUrl = getFilterParamsFromURLQuery(window.location.search);
      const paramsToSet = hasUrlParams ? paramsFromUrl : listingParams;
      if (hasUrlParams) { // coming from URL directly
        listingParams.setFilter({ ...paramsToSet.filter, slug: '' }); // reset slug since location may not have an area page
        listingParams.setSort(paramsToSet.sort);
        window.history.replaceState({ ...window.history.state, as: '/search', url: '/search' }, '', '/search');
      }
      const latitude = paramsToSet.filter.latitude;
      const longitude = paramsToSet.filter.longitude;
      const zoom = Math.floor(paramsToSet.filter.zoom);

      map.drawMap({ latitude, longitude, zoom }, mapElementId);
      if (map.longitude === 0 || map.latitude === 0) {
        map.changeLocation({ lng: longitude, lat: latitude }, zoom);
      }
      map.onZoom(() => listingParams.setZoom(map.zoom));
      map.onPan(() => {
        setMapBoundary(map.boundary);
        listingParams.setLongitude(map.longitude);
        listingParams.setLatitude(map.latitude);
      });
      setHasDrawnMap(true);
      setMapBoundary(map.boundary);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    if (hasDrawnMap) {
      removeAnnotationsNotInNewResult(clusters, 'listing-cluster');
      createClusters(clusters);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusters]);

  useEffect(() => {
    if (hasDrawnMap && schools) {
      removeAnnotationsNotInNewResult(schools, 'school');
      createSchoolPins(schools);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schools]);

  useEffect(() => {
    if (isMobileSlideUpVisible && !MobileSlideUp) {
      setMobileSlideUp(dynamic(import('components/search/map/mobile-slide-up')));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileSlideUpVisible]);

  useEffect(() => { // opens up slide-up if previously left open
    const ids = storage.get(SEARCH_PAGE_MOBILE_SLIDE_UP_LISTING_DATA_COOKIE) as number[];
    if (!!ids?.length) {
      setExistingMobileSlideUpListings(ids);
      setIsMobileSlideUpVisible(true);
    }
  }, []);

  const createClusters = (clusters: Cluster[]) => {
    const pins = clusters.map(cluster => {
      const c = cluster as Cluster;
      const latitude = c.attributes.position.coordinates[1];
      const longitude = c.attributes.position.coordinates[0];
      const listingIds = c.attributes['listing-ids'];
      const listings = c.attributes['listing-ids'];
      const nodePrice = (c as any)?.relationships?.listing?.data?.price;
      const pin = map.createPin(latitude, longitude);
      const isVow = (c as any)?.relationships?.listing?.data?.['is-vow'];
      const isNotAvailable = (c as any)?.relationships?.listing?.data?.status !== 'available';
      const isPrivate = isAuthenticated ? false : isVow || isNotAvailable;
      const nodeId = uuidv4();
      const hasBeenViewed = hasPinBeenViewed(nodeId);
      pin.listingIds = listingIds;
      pin.pinElement.type = 'listing-cluster';
      const container = createRoot(pin.pinElement);
      container.render(<Pin listings={listings} price={nodePrice} isPrivate={isPrivate} hasBeenViewed={hasBeenViewed} />);
      map.addPin((pin as MapkitMappable), () => isNotMobile ? delayHoverPin(latitude, longitude, pin, 'listing', nodeId, nodePrice) : hoverPin(latitude, longitude, pin, 'listing'), delaySetHoveringPinToFalse);
      return pin;
    });
    setCurrentPins(pins);
  };

  const createSchoolPins = (schools: School[]) => {
    schools.map((school: School) => {
      const latitude = school.position.coordinates[1];
      const longitude = school.position.coordinates[0];
      const schoolPin = map.createPin(latitude, longitude);
      const boundary = JSON.parse(JSON.stringify(school.boundary));
      schoolPin.boundary = boundary;
      schoolPin.pinElement.type = 'school';
      schoolPin.schoolData = school;
      const schoolData = { school, latitude, longitude };
      const container = createRoot(schoolPin.pinElement);
      container.render(<SchoolPin />);
      map.addPin((schoolPin as MapkitMappable), () => hoverPin(latitude, longitude, schoolData, 'school'), () => setIsHoveringPin(false));
    });
  };

  let pinTimeout: NodeJS.Timeout;

  const delaySetHoveringPinToFalse = () => {
    setIsHoveringPin(false);
    clearTimeout(pinTimeout);
  };

  const delayHoverPin = function (latitude: number, longitude: number, data: any, type: 'listing' | 'school', id?: string | number, price?: number) {
    pinTimeout = setTimeout(() => {
      hoverPin(latitude, longitude, data, 'listing');
      if (id && price) {
        addPinToVisited(id);
      }
    }, 600);
  };


  const hoverPin = (latitude: number, longitude: number, data: any, type: 'listing' | 'school') => {
    setIsHoveringPin(true);
    setPopupPosition({ latitude, longitude });

    setIsMobileSlideUpVisible(true);
    storage.set(SEARCH_PAGE_MOBILE_SLIDE_UP_LISTING_DATA_COOKIE, data.listingIds);

    if (type === 'listing') {
      setSchoolPopupData(null);
      setClusterPopupData(data);
    }

    if (type === 'school') {
      setClusterPopupData(null);
      setSchoolPopupData(data);
    }
  };

  // TODO: Guard against adding the same element twice to the map
  const removeAnnotationsNotInNewResult = (newClusters: any[], typeToRemove: 'listing-cluster' | 'school') => {
    if (hasDrawnMap && map.annotations && map.annotations?.length > 0) {

      const annotationsToRemove: mapkit.Annotation[] = map.annotations.filter((annotation: mapkit.Annotation) => {
        if ((annotation.element as any)?.type === typeToRemove) {
          const existsInNewResults = newClusters.find((existingAnnotation: Cluster | School) => existingAnnotation.id === annotation.data.id);
          return !existsInNewResults;
        }
      });
      map.removePins(annotationsToRemove);
    }
  };

  const removeBoundaries = () => {
    setFilterBoundaries([]);
    if (schoolParams.filterListings) {
      updateSchoolParams({ ...schoolParams, filterListings: !schoolParams.filterListings });
    }
  };

  const removeStoredListingsForMobileSlideUp = () => {
    storage.delete(SEARCH_PAGE_MOBILE_SLIDE_UP_LISTING_DATA_COOKIE);
    setExistingMobileSlideUpListings([]);
  };

  return (
    <>
      <div id={mapElementId} className={styles.map} data-testid={searchFilterIds.searchMap}>
        <MapControls isSidePanelFullScreen={isSidePanelFullScreen} />
        {isNotMobile && <Popover popupPosition={popupPosition} showsSidePanel={showsSidePanel} />}
        {showsLoading && <div className={styles['map-loading']}>Loading <LoadingSpinner className={styles['map-spinner']} /> </div>}
        {filterBoundaries.length > 0 && <div className={styles['remove-boundary']} onClick={removeBoundaries}> Remove Boundaries </div>}
      </div>
      {!isNotMobile && MobileSlideUp && <MobileSlideUp storedListingIds={existingMobileSlideUpListings} clearStoredListings={removeStoredListingsForMobileSlideUp} />}
    </>
  );
};

export default Map;