
const visitedMapPinKey = 'visitedPins';

export type VisitedPin = { [key: string | string]: boolean };

export function addPinToVisited(id: number | string | undefined) {
  if (id) {
    const existingVisitedPins = getExistingVisitedPins();
    localStorage.setItem(visitedMapPinKey, JSON.stringify({ ...existingVisitedPins, [id]: true }));
  }
}

export function hasPinBeenViewed(id: number | string) {
  const existingVisitedPins = getExistingVisitedPins();
  if (existingVisitedPins?.[id]) {
    return true;
  } else {
    return false;
  }
}

export const getExistingVisitedPins = () => {
  const existingVisitedPins = localStorage?.getItem(visitedMapPinKey) || '{}';
  if (existingVisitedPins) {
    return JSON.parse(existingVisitedPins) as VisitedPin;
  }
};

export const clearExistingVisitedPins = () => { 
  localStorage.setItem(visitedMapPinKey, '{}');
};