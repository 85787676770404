// Note: The following libraries were tried but did not work...
// greiner-hormann - https://github.com/w8r/GreinerHormann
// turf-union - https://github.com/Turfjs/turf-union
// polybooljs (promising) - https://github.com/voidqk/polybooljs
// martinez (active development) - https://github.com/w8r/martinez

import ringIsHole from 'utils/geojson/ring-is-hole';

import type Polygon from 'data/geojson/polygon/type';

export default function geojsonPolygonUnion(...geojsonPolygons: Polygon[]) {
  const coordinates: Polygon['coordinates'][] = [];
  geojsonPolygons.forEach(polygon => {
    const rings = polygon.coordinates;
    if (rings.length === 1) {
      coordinates.push(rings);
    } else {
      const ringWithHoles = [rings[0]];
      rings.slice(1).forEach(ring => {
        if (ringIsHole(ring)) {
          ringWithHoles.push(ring);
        } else {
          coordinates.push([ring]);
        }
      });
      coordinates.push(ringWithHoles);
    }
  });
  return multiPolygonFromCoordinates(coordinates);
}

function multiPolygonFromCoordinates(coordinates: Polygon['coordinates'][]) {
  if (coordinates.length > 0) {
    return {
      type: 'MultiPolygon',
      coordinates: coordinates,
    };
  }
}
