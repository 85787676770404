import type Coordinate from 'data/geojson/coordinate/type';

export default function ringIsHole(coords: Coordinate[]) {
  if (coords.length > 2) {
    let area = 0;
    for (let i = 0; i < coords.length - 1; i++) {
      const [x1, y1] = coords[i];
      const [x2, y2] = coords[i + 1];
      area += radian(x2 - x1) * (2 + Math.sin(radian(y1)) + Math.sin(radian(y2)));
    }
    return area >= 0; // ClockWise => Hole
  }
}

function radian(degree: number) {
  return degree * Math.PI / 180;
}
