import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const LeftArrowIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="9" viewBox="0 0 5 9" fill="none" className={className} onClick={onClick}>
      <path d="M5 7.72188L1.90958 4.375L5 1.02812L4.04858 0L0 4.375L4.04858 8.75L5 7.72188Z" fill="black"/>
    </svg>
  );
};

export default LeftArrowIcon;
