import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const RightArrowIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="9" viewBox="0 0 5 9" fill="none" className={className} onClick={onClick}>
      <path d="M0 1.02812L3.09042 4.375L0 7.72188L0.951417 8.75L5 4.375L0.951417 0L0 1.02812Z" fill="black"/>
    </svg>
  );
};

export default RightArrowIcon;
