import React, { MouseEvent } from 'react';
import { testIds } from 'constants/test-constants';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const SchoolPinIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="36" className={className} onClick={onClick} data-testid={testIds.schoolPin}>
      <path fill="#07B0C0" stroke="#FFF" strokeWidth="2" d="M25.722 13.29C25.722 6.503 20.19 1 13.362 1 6.533 1 1 6.502 1 13.29c0 .179-.009.803.072 1.317.87 5.771 6.463 13.566 12.29 20.317l.02.023c.121.14 11.666-13.455 12.34-20.613.007-.07 0-.762 0-1.044"
      ></path>
      <path fill="#FFF" d="M18.302 14v2.735c0 .22-.108.373-.309.459-1.376.59-2.75 1.184-4.131 1.762a.635.635 0 01-.445-.004 238.329 238.329 0 01-4.1-1.763c-.133-.059-.297-.24-.3-.368-.026-.933-.014-1.866-.014-2.821l2.45.695c.644.184 1.286.379 1.934.548.164.043.358.045.52 0 1.388-.385 2.77-.786 4.155-1.181.07-.02.141-.037.24-.062zM7.526 12.537v.3c0 1.008-.005 2.016.005 3.024a.786.786 0 00.123.374c.128.213.098.403-.05.603.405.338.392.828.481 1.28.008.04.013.081.016.122.011.174.097.44.018.505a1.92 1.92 0 01-.765.393c-.344.08-.625-.147-.875-.363-.046-.04-.054-.149-.044-.22.053-.357.095-.717.185-1.064.06-.23.195-.44.317-.705-.12-.2-.141-.443.108-.666.041-.037.03-.14.031-.212.002-1.098-.003-2.197.005-3.295.001-.174-.058-.241-.22-.285a34.162 34.162 0 01-1.603-.465c-.101-.033-.173-.16-.258-.245.085-.076.158-.194.257-.223 2.768-.799 5.539-1.59 8.31-2.377.103-.03.23-.02.335.01 2.739.778 5.476 1.56 8.213 2.345a.595.595 0 01.257.127c.13.132.045.3-.184.367-.668.195-1.337.387-2.005.579-2.047.584-4.094 1.165-6.138 1.755a1.103 1.103 0 01-.645.004c-1.886-.546-3.775-1.082-5.663-1.62-.054-.015-.11-.025-.211-.048z"
      ></path>
    </svg>
  );
};

export default SchoolPinIcon;
