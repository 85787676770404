import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import ListingCardSmall from 'components/listing-card/small';
import LoadingSpinner from 'components/loading-spinner';
import { PreferencesContext } from 'contexts/preferences';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import styles from './style.module.scss';
import { mapPageIds } from 'constants/test-constants';
import { ProvinceOrStateCode, isUsStateCode } from 'utils/province_or_state';
import ProviderDisclaimer from 'components/dynamic-page/new-area-listings-page/provider-disclaimer';
import Button from 'components/control/button';
import prioritizeFeeds from 'utils/prioritize-feeds';
import { getListings } from 'data/listing';

import type Listing from 'data/listing';
import type { IPreferences } from 'contexts/preferences';

interface Props {
  listingIds: number[];
}

const PAGE_SIZE = 24;
const INITIAL_PAGE = 1;

export default function ListingPopover({ listingIds }: Props) {
  const { listingParams } = useContext(PreferencesContext) as IPreferences;
  const [listings, setListings] = useState<Listing[]>([]);
  const [pageNumber, setPageNumber] = useState(INITIAL_PAGE);
  const moreListingsAvailableToLoad = listingIds.length > listings.length;
  const [doesContainerUsListings, setDoesContainerUsListings] = useState(false);
  const [disclaimerRead, setDisclaimerRead] = useState(false);
  const showsDisclaimer = doesContainerUsListings && !disclaimerRead;

  useEffect(() => {
    grabListings(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingIds]);

  useEffect(() => {
    if (listings.length) {
      const containsUSListings = listings.some(item => isUsStateCode(item.province?.toLowerCase() as ProvinceOrStateCode));
      setDoesContainerUsListings(containsUSListings);
    }
  }, [listings]);

  const grabListings = async (overwriteListings: boolean, page?: number) => {
    const params = {
      sort: '-date',
      page: {
        size: PAGE_SIZE,
        number: page || INITIAL_PAGE,
      },
      filter: {
        ids: listingIds.slice(0, 500).toString(),
        status: listingParams.filter.status,
        rental: listingParams.filter.rental,
      },
    };
    const { listings } = await getListings(params);
    if (overwriteListings) {
      setListings(listings);
    } else {
      setListings(existingListings => [...existingListings, ...listings]);
    }
  };

  const isItemLoaded = (index: number) => index < listings.length;

  const ListingCardOrSpinner = ({ index, style }: { index: number; style: CSSProperties}) => {
    if (showsDisclaimer && index === listings.length && listings.length > 0) {
      const providers = prioritizeFeeds([...new Set(listings.map(l => l.providerId))]);
      return (
        <div style={style}>
          {moreListingsAvailableToLoad &&
            <Button label='Load More Listings' theme='primary' onClick={() => setDisclaimerRead(true)} className={styles['view-more']} />
          }
          {providers.map(p => <ProviderDisclaimer key={p} provider={p}/>)}
        </div>
      );
    } else if (!isItemLoaded(index) && (listings.length < listingIds.length && listings.length !== 0)) {
      return <div style={style}><LoadingSpinner /></div>;
    } else if (index < listings.length) {
      return <div style={style}><ListingCardSmall listing={listings[index]}/></div>;
    } else {
      return <></>;
    }
  };

  const loadMore = () => {
    if (!showsDisclaimer && listingIds.length > 0) {
      const newPage = pageNumber + 1;
      setPageNumber(newPage);
      return new Promise(resolve => {
        grabListings(false, newPage);
        resolve();
      }) as Promise<void>;
    }
  };

  useEffect(() => {
    if (disclaimerRead) {
      loadMore();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disclaimerRead]);

  return (
    <div id={'hover-popover'} className={styles.component} data-testid={mapPageIds.listingPinPopover}>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={listingIds.length}
            threshold={5}
            loadMoreItems={loadMore}
          >
            {({ onItemsRendered, ref }) => {
              return (
                <List
                  height={height}
                  itemCount={showsDisclaimer ? listings.length+1 : listings.length}
                  itemSize={103}
                  onItemsRendered={onItemsRendered}
                  width={width}
                  ref={ref}
                >
                  {ListingCardOrSpinner}
                </List>
              );}}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  );
}
