type CacheItem<T> = {
    data: T;
    expiresAt: number; // Timestamp indicating when the data expires
};

export class GeohashCache<T> {
  private cache: Map<string, CacheItem<T[]>>;
  private defaultExpirationMs: number;

  constructor(defaultExpirationMs = 60000) { // default expiration time set to 60 seconds
    this.cache = new Map();
    this.defaultExpirationMs = defaultExpirationMs;
  }

  /**
     * Stores an array of items in the cache under the specified geohash key.
     * 
     * @param geohash The geohash key under which to store the items.
     * @param items The array of items to be stored.
     * @param expirationMs Optional specific expiration time in milliseconds for this entry.
     */
  set(geohash: string, items: T[], expirationMs?: number): void {
    const expiresAt = Date.now() + (expirationMs || this.defaultExpirationMs);
    this.cache.set(geohash, { data: items, expiresAt });
  }

  /**
     * Retrieves items from the cache for the specified geohash key.
     * If the entry is expired or does not exist, undefined is returned.
     * 
     * @param geohash The geohash key for which to retrieve the items.
     * @returns The array of items if available and not expired, otherwise undefined.
     */
  get(geohash: string): T[] | undefined {
    const item = this.cache.get(geohash);
    if (item && Date.now() < item.expiresAt) {
      return item.data;
    }

    // If the item is expired clean up
    if (item) {
      this.cache.delete(geohash);
    }
    return undefined;
  }

  /**
     * Checks if an entry for the specified geohash key is expired.
     * 
     * @param geohash The geohash key to check for expiration.
     * @returns true if the entry is expired or does not exist, false otherwise.
     */
  isExpired(geohash: string): boolean {
    const item = this.cache.get(geohash);
    if (!item) 
      return true;
    return Date.now() >= item.expiresAt;
  }
}
