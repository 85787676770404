import React, { useContext } from 'react';
import Link from 'components/link';
import Button from 'components/control/button';
import { Boundary, useOverlayContext } from 'contexts/overlay';
import { PopupContext } from 'contexts/popup';
import styles from './style.module.scss';
import { searchFilterIds } from 'constants/test-constants';

import type { IPopupContext } from 'contexts/popup';

export default function SchoolPopover() {
  const { schoolPopupData } = useContext(PopupContext) as IPopupContext;
  const { filterBoundaries, setFilterBoundaries } = useOverlayContext();

  const { school } = schoolPopupData || {};
  const { boundary } = school || {};

  const handleOnClick = () => {
    if (boundary && boundary != null) {
      const schoolBoundary = boundary as Boundary;
      setFilterBoundaries([...filterBoundaries, schoolBoundary]);
    }
  };
  
  return !school ? null : (
    <div id={'hover-popover'} className={styles.component} data-testid={searchFilterIds.schoolPinPopover}>
      <div className={styles.top} onClick={() => window.open(`/school/${school.slug}`, '_blank')}>
        <div className={styles['rating-section']}>
          <div className={styles.rating}>
            {school.eqaoRating?.toFixed(1)}
          </div>
          <div className={styles['rating-range']}> out of 4 </div>
        </div>
        <div className={styles['school-info']}>
          <div className={styles['school-name']}>
            {school.name}
          </div>
          <span className={styles['eqao-credit']}>Ranking Provided by EQAO</span>
          <div className={styles['school-attributes']}>
            <div>
              {school.grades}
            </div>
            <div>
              {school.language}
            </div>
          </div>
        </div>
      </div>
      {boundary && <Button label="Only Show Listings in School Boundary" theme="primary" className={styles['filter-by-boundary']} onClick={handleOnClick} />}
      <section className={styles.footer}>
        {!boundary && <div className={styles['no-boundary']}> No boundary </div>}
        {boundary && <div>School boundaries change often. Please <Link href='/school-boundary-lookup'>confirm boundaries</Link>  before making any purchasing decision.</div>}
      </section>
    </div>
  );
}
