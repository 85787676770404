import atob from 'atob';

// Decrypt the API content by base64 decoding 3 times.
export default function decryptPayload(payload: Record<string, any>) {
  if (payload && typeof payload.data === 'string') {
    const decryptedContent = JSON.parse(atob(atob(atob(payload.data))));
    payload.data = decryptedContent.data;
    if (decryptedContent.meta) {
      payload.meta = decryptedContent.meta;
    }
  }
  return payload;
}
