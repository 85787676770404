/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useRef, MouseEvent, useEffect, useState, useMemo } from 'react';
import Link from 'components/link';
import FavouriteToggle from 'components/listing-card/favourite-toggle';
import ListingPrice from 'components/listing-price';
import SchemaOrg from 'components/schema-org';
import { ModalContext, IModalContext } from 'contexts/modal';
import { UserContext, IUserContext } from 'contexts/user';
import { buildClassName } from 'utils/build-class-name';
import ListingBedBathParking from './footer';
import Image from 'components/listing-image';
import getListingImageAlt from 'utils/image-alt-description';
import getImageSrc from 'utils/get-image';
import styles from './style.module.scss';
import { isListingVisibleToUser } from 'utils/listing-agreement-helper';
import ListingCardNewOverlay from 'components/listing-card-new-overlay';
import { listingCardIds, mapPageIds } from 'constants/test-constants';
import ProviderLogo from '../provider-logo';
import { locationsToNotUseListingModals } from 'constants/no-listing-modal-locations';
import { trackEvent } from 'utils/google-tag-manager';
import ConditionalWrapper from 'components/conditional-wrapper';
import { GTM_CLICK_MAP_PIN_LISTING } from 'constants/events';
import { useIsMobile } from 'hooks/use-size-class';
import generateFullAddress from 'utils/get-full-address';
import { useTranslation } from 'react-i18next';
import { SiteNameSpace } from 'constants/locale';

import type Listing from 'data/listing';
interface Props {
  listing: Listing;
}

export default function ListingCardSmall({ listing }: Props) {
  const { id: listingId, imageUrl, imageDesc, addressPathWithFallback, isCrea, isPillar9Vow, city,
    province, isPrivate: listingIsPrivate, isImageReady, position, getStreet, providerId, isSignInAndTermsOfUseRequired } = listing;
  const { openModal } = useContext(ModalContext) as IModalContext;
  const { isAuthenticated, acceptedBrowserBasedTerms, acceptedTerms } = useContext(UserContext) as IUserContext;
  const [showsListing, setShowsListing] = useState<boolean>(false);
  const listingCardElement = useRef<HTMLDivElement>(null);
  const isPrivate = !isAuthenticated && listingIsPrivate;
  const cardImageClass = buildClassName(styles.image, !showsListing && styles['blur-image']);
  const street = getStreet(isAuthenticated);
  const imageAlt = imageDesc && imageDesc !== '' ? imageDesc : getListingImageAlt(listing);
  const shouldScramble = isPillar9Vow && !showsListing;
  const { t } = useTranslation(SiteNameSpace.Modal);

  const isNoModalLocation = province !== null && locationsToNotUseListingModals.includes(province);
  const isMobile = useIsMobile();
  const noModal = isNoModalLocation || isMobile;
  

  useEffect(() => {
    if (providerId) {
      setShowsListing(isListingVisibleToUser(isPrivate, providerId, acceptedBrowserBasedTerms, acceptedTerms));
    }
  }, [isPrivate, providerId, acceptedBrowserBasedTerms, acceptedTerms]);

  const openListingModal = (event: MouseEvent) => {
    if ((event.target as Element).tagName !== 'A') {
      trackEvent(GTM_CLICK_MAP_PIN_LISTING);
      openModal('listing', { externalUrl: addressPathWithFallback, listingId });
      if (isPrivate || (isSignInAndTermsOfUseRequired && !showsListing && !isAuthenticated)) {
        openModal('login-registration', { warningMessage: t('login-registration.form.signinRequiredWarning') });
      } else if (!showsListing) {
        openModal('terms-of-use', { providerId });
      }
    }
  };

  const isHidden = isPrivate || (isSignInAndTermsOfUseRequired && !showsListing && !isAuthenticated);
  const isTermsRequired = !showsListing && (isCrea || isSignInAndTermsOfUseRequired);

  const trackEvents = () => {
    trackEvent('Xp_UiAddresspView');
    trackEvent(GTM_CLICK_MAP_PIN_LISTING);
  };

  const CardSmall = useMemo(() => {
    return (
      <ConditionalWrapper condition={noModal} wrapper={children => <Link href={addressPathWithFallback} target={noModal ? '_blank' : ''} onClick={() => trackEvents()}>{children}</Link>}>
        <div className={buildClassName(styles.component, !showsListing && styles.blur)} ref={listingCardElement} onClick={noModal ? () => {} : openListingModal} data-testid={mapPageIds.listingsCardPopover}>
          <div className={styles.image}>
            <Image item={{ image: getImageSrc(imageUrl), alt: `${imageAlt} | Card Image`, id: `${listing.id}-card-image`, isImageReady, addedAt: listing.addedAt }} className={cardImageClass} testId={listingCardIds.listingImage} />
          </div>
          <div className={styles.right}>
            {isHidden || (isTermsRequired && isAuthenticated) || (isTermsRequired && isCrea) ?
              <ListingCardNewOverlay isLoginRequired={isHidden} isTermsRequired={isTermsRequired} isSmallCard={true} isCrea={isCrea} /> :
              <>
                <div className={styles['price-time']} data-testid={listingCardIds.listingPrice}>
                  <ListingPrice listing={listing} isVisible={showsListing} isMinimal={true} isSmallCard={true} shouldScramble={shouldScramble} />
                  {showsListing && <div className={styles['favourite-mobile']}><FavouriteToggle listingId={listingId} isSmallCard={true} /> </div>}
                </div>
                <SchemaOrg itemType="SingleFamilyResidence">
                  <SchemaOrg itemProp="address" itemType="PostalAddress">
                    {noModal ?
                      <div className={styles['street-address']} data-testid={listingCardIds.listingAddress}>
                        <p>{generateFullAddress(street, city, province, shouldScramble)}</p>
                      </div> :
                      <p className={styles['street-address']} data-testid={listingCardIds.listingAddress}>
                        <Link target="_blank" href={addressPathWithFallback}>{generateFullAddress(street, city, province, shouldScramble)}</Link>
                      </p>
                    }
                    <meta itemProp="streetAddress" content={street ? street : ''} />
                    <meta itemProp="addressLocality" content={city ? city : ''} />
                    <meta itemProp="addressRegion" content={province ? province : ''} />
                  </SchemaOrg>
                  <SchemaOrg itemProp="geo" itemType="GeoCoordinates">
                    <meta itemProp="latitude" content={position ? position.latitude.toString() : ''} />
                    <meta itemProp="longitude" content={position ? position.longitude.toString() : ''} />
                  </SchemaOrg>
                </SchemaOrg>
                <ListingBedBathParking listing={listing} isPrivate={isHidden} requiresTerms={isTermsRequired} openModal={openModal} />
                <ProviderLogo listing={listing} isSmallCard={true} />
              </>
            }
          </div>
        </div>
      </ConditionalWrapper>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showsListing]);

  return CardSmall;
}
