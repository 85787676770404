import { GeohashCache } from './geohash_cache';

export class ZoomLevelGeohashCache<T> {
  private caches: Map<number, GeohashCache<T>>;
  private defaultExpirationMs: number;

  constructor(defaultExpirationMs = 60000) {
    this.caches = new Map();
    this.defaultExpirationMs = defaultExpirationMs;
  }

  private getOrCreateCache(zoomLevel: number): GeohashCache<T> {
    if (!this.caches.has(zoomLevel)) {
      this.caches.set(zoomLevel, new GeohashCache<T>(this.defaultExpirationMs));
    }
    return this.caches.get(zoomLevel) as GeohashCache<T>;
  }

  set(zoomLevel: number, geohash: string, items: T[], expirationMs?: number): void {
    if (zoomLevel < 0 || zoomLevel > 17) {
      throw new Error('Zoom level must be between 0 and 17.');
    }
    const cache = this.getOrCreateCache(zoomLevel);
    cache.set(geohash, items, expirationMs);
  }

  get(zoomLevel: number, geohash: string): T[] | undefined {
    if (!this.caches.has(zoomLevel)) 
      return undefined;
    const cache = this.caches.get(zoomLevel);
    return cache != undefined ? cache.get(geohash) : undefined;
  }

  isExpired(zoomLevel: number, geohash: string): boolean {
    if (!this.caches.has(zoomLevel)) 
      return true;
    const cache = this.caches.get(zoomLevel);
    return cache != undefined && cache.isExpired(geohash);
  }
}
