import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MapContext } from 'contexts/map';
import { PopupContext } from 'contexts/popup';
import ListingPopover from './listings';
import SchoolPopover from './school';
import styles from './style.module.scss';
import useWindowWidth from 'hooks/use-window-width';
import sidePanelStyles from 'components/search/side-panel/style.module.scss';
import convertCssStringPropToNumber from 'utils/convert-css-prop';

import type { Offsets } from '@zoocasa/node-kit/map/types';
import type { IMapContext } from 'contexts/map';
import type { IPopupContext } from 'contexts/popup';

interface Props {
  popupPosition: {
    latitude: number;
    longitude: number;
  };
  showsSidePanel: boolean;
}

export default function Popover({ popupPosition: { latitude, longitude }, showsSidePanel }: Props) {
  const { map } = useContext(MapContext) as IMapContext;
  const { popupPosition, setIsHoveringPopup, clusterPopupData, schoolPopupData } = useContext(PopupContext) as IPopupContext;
  const windowWidth = useWindowWidth();
  const popoverID = 'hover-popover';
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const getPopoverHeight = () => {
    if (clusterPopupData) {
      return 110 * (clusterPopupData.listingIds.length < 4 ? clusterPopupData.listingIds.length : 3);
    } else {
      return 153;
    }
  };

  const getPopoverPosition = useCallback(() => {
    const popover = document.getElementById(popoverID);
    const isSidePanelEnlarged = windowWidth > convertCssStringPropToNumber(sidePanelStyles.sidePanelBreakpoint);
    const sidePanelSize = isSidePanelEnlarged ? convertCssStringPropToNumber(sidePanelStyles.sidePanelEnlarged) : convertCssStringPropToNumber(sidePanelStyles.sidePanelSmall);
    const offsets: Offsets = {
      xOffset: showsSidePanel ? sidePanelSize : 0,
      yOffset: 100,
      framePadding: 15,
      frameOffset: 15,
      targetPadding: 50,
    };
    const { x, y } = map.getHoverPosition(latitude, longitude, popover!, offsets);
    setLeft(x);
    setTop(y);
  }, [latitude, longitude, map, showsSidePanel, windowWidth]);

  const renderPopover = useMemo(() => {
    return clusterPopupData && <ListingPopover listingIds={clusterPopupData.listingIds} />;
  }, [clusterPopupData]);

  useEffect(() => {
    if (latitude && longitude) {
      getPopoverPosition();
    } 
  }, [getPopoverPosition, latitude, longitude]);

  return !popupPosition ? null : (
    <div
      onMouseOver={() => setIsHoveringPopup(true)}
      onMouseLeave={() => setIsHoveringPopup(false)}
      id={popoverID}
      style={{ height: getPopoverHeight(), top, left }}
      className={styles.component}
    >
      {renderPopover}
      {schoolPopupData && <SchoolPopover />}
    </div>
  );
}
